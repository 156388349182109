<template>
  <v-footer padless>
    <v-col class="text-center orange white--text" cols="10"> Abadía Consulting S.A. de C.V.</v-col>
    <v-col class="text-center grey white--text" cols="2">
      <v-img :src="'images/fondos/l.svg'" max-height="21"
                contain></v-img>
     </v-col>
  </v-footer>
</template>
<script>
export default {
  name: "pageFooter",
};
</script> 