<template>
  <div id="dashboard">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex child-flex" sm="12" md="6" lg="6">
          <v-card class="mx-auto" color="#F5F5F5">
            <v-img
              :src="'images/fondos/esferas.jpg'"
              :lazy-src="'images/fondos/esferas.jpg'"
            ></v-img>

            <v-card-title>Esferas de contacto </v-card-title>

            <v-card-actions>
              <v-btn color="#F4511E" text @click="esferas = !esferas">
                Más
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="esferas = !esferas">
                <v-icon>{{
                  esferas ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="esferas">
                <v-divider></v-divider>

                <v-card-text>
                  La tecnología, hoy en día es parte de nuestro de nuestra vida
                  cotidiana por ello, todas las esferas y todos los socios
                  podemos hacer referencia.
                  <br />Así mismo menciona con las que podemos convivir mejor:

                  <ul>
                    <li>Marketing</li>
                    <li>Tecnología</li>
                    <li>Empresarial</li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col class="d-flex child-flex" sm="12" md="6" lg="6">
          <v-card class="mx-auto" color="#F5F5F5">
            <v-img
              :src="'images/fondos/referencia.jpg'"
              :lazy-src="'images/fondos/referencia.jpg'"
            ></v-img>

            <v-card-title>Buenas Referencias</v-card-title>

            <v-card-actions>
              <v-btn color="#F4511E" text @click="referencia = !referencia">
                Más
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="referencia = !referencia">
                <v-icon>{{
                  referencia ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="referencia">
                <v-divider></v-divider>

                <v-card-text>
                  La tecnología, hoy en día es parte de nuestro de nuestra vida
                  cotidiana por ello, todas las esferas y todos los socios
                  podemos hacer referencia.
                  <br />Así mismo menciona con las que podemos convivir mejor:
                  <ul>
                    <li>Empresarial</li>
                    <li>Marketing</li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      
      <v-row align="center">
        
        <v-spacer></v-spacer>
        <div class="my-2">
          <v-btn
            depressed
            large
            rounded
            color="primary white--text"
            href="doctos/PortafoliodeAbadia.pdf"
            download="catalogo.pdf"
            >Descargar Portafolio</v-btn
          >
        </div>
        <v-spacer></v-spacer>
      </v-row>
      <v-row align="center">
        <div class="my-2">
          <v-btn
            depressed
            large
            rounded
            color="primary white--text"
            to="/products"
            >Anterior</v-btn
          >
        </div>
        <v-spacer></v-spacer>
        <div class="my-2">
          <v-btn
            depressed
            large
            rounded
            color="primary white--text"
            to="/questions"
            >Siguiente</v-btn
          >
        </div>
      </v-row>
    </v-container>
    <pageFooter></pageFooter>
  </div>
</template>
<script>
import pageFooter from "@/components/Footer.vue";
export default {
  components: {
    pageFooter,
  },
  data() {
    return {
      dialog: false,
      text: "inin",
      referencia: false,
      esferas: false,
    };
  },
  methods: {},
  created() {},
};
</script>
